import SecaoLateral from "../../Components/SecaoLateral/SecaoLateral";
import { useAtualizaEscalasIdentificadorContext } from "../../Context/AtualizaRelEscalasIdentificador.context";

const { useState } = require("react")

const AdminEscalasIdentificador = () => {
    let [page, setPage] = useState("init");

    let { handleOnChange, handleUploadImage, handleSubmit } = useAtualizaEscalasIdentificadorContext();

    return (
        <div className="AdminEscalasIdentificador">
            <SecaoLateral
                Greeting_OR_Title={"Escalas e identificador"}
            />
            <div className="Body-adminEscalaIdentificador">
                {page === "init" && (
                    <div className="Init">
                        <div className="Escala-new-report">
                            <h2>Micro Escala</h2>
                            <div className="flex-escala-newReport">
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMicroDescritivo"> Descrição da microescala </label>
                                    <textarea type="text" id="escalaMicroDescritivo" name="escalaMicroDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                </div>
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMicroImagem">Imagem da caracteroizacao</label>
                                    <input type="file" name="escalaMicroImagem" id="escalaMicroImagem" onChange={handleUploadImage} />
                                </div>
                            </div>
                        </div>
                        <div className="Escala-new-report">
                            <h2>Meso Escala</h2>
                            <div className="flex-escala-newReport">
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMesoDescritivo"> Descrição da mesoescala </label>
                                    <textarea type="text" id="escalaMesoDescritivo" name="escalaMesoDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                </div>
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMesoImagem">Imagem da caracteroizacao</label>
                                    <input type="file" name="escalaMesoImagem" id="escalaMesoImagem" onChange={handleUploadImage} />
                                </div>
                            </div>
                        </div>
                        <div className="Escala-new-report">
                            <h2>Macro Escala</h2>
                            <div className="flex-escala-newReport">
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMacroDescritivo"> Descrição da macroescala </label>
                                    <textarea type="text" id="escalaMacroDescritivo" name="escalaMacroDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                </div>
                                <div className="form-control-new-report">
                                    <label htmlFor="escalaMacroImagem">Imagem da caracteroizacao</label>
                                    <input type="file" name="escalaMacroImagem" id="escalaMesoImagem" onChange={handleUploadImage} />
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSubmit} className="NovoRelatorioAdminButton">
                            Salvar Novo Relatorio
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AdminEscalasIdentificador;