import { createContext, useContext, useState } from "react";
import AdminEscalasIdentificador from "../Pages/Admin-escalas-e-identificador/AdminEscalasIdentificador";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../Service/ApiService";
import { PORTAL_PATHS } from "../Router/Path";

export const AtualizaEscalasIdentificadorContext = createContext();
export let useAtualizaEscalasIdentificadorContext = () => { return useContext(AtualizaEscalasIdentificadorContext) }

export default function AtualizaEscalasIdentificadorContextProvider(){
    let [ Relatorio, setRelatorio ] = useState();
    let navigate = useNavigate();
    let { relId } = useParams();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setRelatorio(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log(Relatorio);
    };

    const handleUploadImage = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
    
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]; 
                setRelatorio(prevData => ({
                    ...prevData,
                    [name]: base64String 
                }));
                console.log("Imagem convertida para base64:", base64String);
            };
    
            reader.readAsDataURL(file); 
        }
    };

    async function handleSubmit(e){
        e.preventDefault();
        await ApiService.post(`/AtualizarRelatorio/${relId}`, Relatorio)
        navigate(`${PORTAL_PATHS.ADMIN_ATUALIZAR_RELATORIO}/${relId}`);
    }

    let value = {
        handleOnChange,
        handleUploadImage,
        handleSubmit
    }
    
    return(
        <AtualizaEscalasIdentificadorContext.Provider value={value}>
            <AdminEscalasIdentificador />
        </AtualizaEscalasIdentificadorContext.Provider>
    )
}