import axios from "axios";
import LocalStorageService from "../Service/LocalStorageService"

export const BASE_BACKEND = "https://vizzion.app.br"; //validando alteracao em prod
//export const BASE_BACKEND = "http://localhost:8080"
//export const BASE_BACKEND = "https://site-institucional-proxy-api.fizmcj.easypanel.host/proxy"

const api = axios.create({
    baseURL: BASE_BACKEND,
    headers:{
        'Content-Type':'application/json',
        
    },
    //withCredentials:true
})
//jjjj

api.interceptors.request.use(
    config => {
        const token = LocalStorageService.getToken();
        console.log("Request Config:", config);
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            console.log("Token adicionado:", token);
        }
        return config;
    },
    error => {
        console.error("Request Error:", error);
        return Promise.reject(error);
    }
);

/*
api.interceptors.response.use(
    response => {
        console.log("Response Data:", response.data);
        return response;
    },
    error => {
        console.error("Response Error:", error.message);
        if (error.response) {
            console.error("Status:", error.response.status);
            console.error("Data:", error.response.data);
        }
        return Promise.reject(error);
    }
);
*/
export default api;

